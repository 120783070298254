import * as React from "react"
import Layout from "../components/layout"
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Section from "../components/Section"
import Location from "../components/Location"
import {
  TextContent,
  MaxContainer,
  TwoColumnGrid,
  TestimonialPost,
  Row,
} from '../styles/utility'
 
const TestimonialsPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicCustomerTestimonials.edges[0].node.data
  return (
    <Layout>
      <SEO title="Customer Testimonials" description="Interested in booking an appointment with us? View our customer testimonials to learn about our affordable and quality dental services." noFollow={false} />
      <MaxContainer>
        <Row>
          <Section pageTitle="Client Testimonials">
              <TextContent>
                  <p>{RichText.asText(document.long_description)}</p> 
              </TextContent>
              <TwoColumnGrid>
                {document.testimonials.map((testimonial, index) => (
                  <TestimonialPost key={index}>
                    <p>{RichText.asText(testimonial.testimonial_description)}</p>
                    <p>- {RichText.asText(testimonial.testimonial_name)}</p>
                  </TestimonialPost>
                ))}
              </TwoColumnGrid>
            </Section>
            <Section>
              <Location />
            </Section>
        </Row>
      </MaxContainer>
    </Layout>
  )
}

export const query = graphql`
  query Testimonials {
    allPrismicCustomerTestimonials {
      edges {
        node {
          data {
            long_description {
              text
            }
            testimonials {
              testimonial_description {
                text
              }
              testimonial_name {
                text
              }
            }
            short_description {
              text
            }
          }
        }
      }
    }
  }
`

export default TestimonialsPage